// Utility function to check if a string contains HTML tags
export function isHTMLString(input) {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i
    return htmlRegex.test(input)
}

export const utcToPst = (localDate, isDate) => {
    if (!localDate) return
    let date = new Date(localDate)
    let pstTime = date?.toLocaleString(`en-US`, {
        timeZone: `America/Los_Angeles`,
        year: `numeric`,
        month: `2-digit`,
        day: `2-digit`,
        hour: `2-digit`,
        minute: `2-digit`,
    })
    let pstDate = date?.toLocaleString(`en-US`, {
        timeZone: `America/Los_Angeles`,
        year: `numeric`,
        month: `2-digit`,
        day: `2-digit`,
    })
    return isDate ? pstDate : pstTime
}

export const getCookie = (cookieName) => {
    const _cookies = document.cookie
    return _cookies
        ?.split(`;`)
        ?.filter((item) => item.includes(cookieName))[0]
        ?.split(`=`)[1]
}

export const formatAmount = (amount) => {
    if (amount && typeof amount === `object` && amount.$numberDecimal) {
        return parseFloat(amount.$numberDecimal).toFixed(2);
    }
    return parseFloat(amount) || 0;
};

export const convertCamelCaseToString = (camelCaseString) => {
    return camelCaseString.replace(/([A-Z])/g, ` $1`).replace(/^./, function (str) {
        return str.toUpperCase();
    });
}