/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { ReactModal } from 'App/Components/Common/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { setPreferredLocation } from 'App/Redux/actions'
import { getCookie } from 'App/utils'

export const PreferredLocation = ({ setShowPreferredLocation }) => {
  const user = useSelector(({ user: { user } }) => user)
  const preferredLocation = useSelector(
    ({ user: { preferredLocation } }) => preferredLocation,
  )
  const switchLocation = useSelector(
    ({ user: { switchLocation } }) => switchLocation,
  )
  const [preferedLocationModal, setPreferedLocationModal] = useState(false)
  const [preferred, setPreferred] = useState(false)
  const dispatch = useDispatch()
  const _preferredLocation = getCookie(`preferredLocation`)
    ? JSON.parse(getCookie(`preferredLocation`))
    : `{}`

  useEffect(() => {
    if (user.profile.sub !== getCookie(`userId`)) {
      document.cookie = `preferredLocation=; path=/`
    }

    if (user?.profile?._locations?.length === 1) {
      dispatch(
        setPreferredLocation({
          ...user?.profile?._locations[0],
          always: false,
        }),
      )
    } else {
      if (
        !preferredLocation?.currentlySet &&
        !_preferredLocation?.always &&
        !preferredLocation?.switchLocation
      ) {
        setPreferedLocationModal(true)
      } else {
        dispatch(
          setPreferredLocation(
            preferredLocation?._id &&
              _preferredLocation?._id !== preferredLocation?._id
              ? preferredLocation
              : _preferredLocation,
          ),
        )
        setPreferedLocationModal(false)
      }

      if (
        !switchLocation &&
        !user?.profile?._locations
          .map((location) => location?._id !== _preferredLocation?._id)
          .includes(false)
      ) {
        setPreferedLocationModal(true)
        dispatch(
          setPreferredLocation({
            ...preferredLocation,
            always: !preferredLocation?.always,
          }),
        )
      }
    }
  }, [])

  return preferedLocationModal ? (
    <ReactModal
      title="Prefer Location"
      closeButton={
        !preferredLocation?._id?.length || preferredLocation?.profileLocation
      }
      className="py-2"
      preferLocation="96"
      closeModal={() => {
        setPreferedLocationModal(!preferedLocationModal)
        setShowPreferredLocation && setShowPreferredLocation()
        dispatch(
          setPreferredLocation({
            ...preferredLocation,
            currentlySet: true,
          }),
        )
      }}
    >
      <div className="flex flex-col content-evenly w-96 overflow-visible ">
        {user.profile._locations.map((location, index) => (
          <div
            onClick={() => {
              dispatch(
                setPreferredLocation({
                  ...preferredLocation,
                  ...location,
                }),
              )

              setPreferred(true)
              document.cookie = `preferredLocation=${JSON.stringify({
                ...location,
                always: false,
              })}; path=/`
            }}
            key={index + `location`}
            className={`w-80 p-2 mx-auto my-1 rounded text-center text-sm border-1 border-gray-400 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer ${
              _preferredLocation?._id === location?._id
                ? `bg-blue-500 text-white border-blue-500`
                : `bg-white text-gray-400`
            }`}
          >
            {location.name}
          </div>
        ))}
        {preferred && (
          <div className="w-80 mx-auto flex justify-end">
            <span className="text-xs text-gray-500 my-auto">
              Remember Preference
            </span>
            <Checkbox
              type="checkbox"
              name="always"
              value={
                preferredLocation?.profileLocation
                  ? false
                  : preferredLocation.always
              }
              setValue={() => {
                dispatch(
                  setPreferredLocation({
                    ...preferredLocation,
                    always: !preferredLocation.always,
                    currentlySet: !preferredLocation.currentlySet,
                  }),
                )
                document.cookie = `preferredLocation=${JSON.stringify({
                  ...preferredLocation,
                  always: true,
                })}; path=/`
                setShowPreferredLocation && setShowPreferredLocation()
                setPreferedLocationModal(!preferedLocationModal)
              }}
            />
          </div>
        )}
      </div>
    </ReactModal>
  ) : (
    <></>
  )
}
