import React, { useEffect, useRef } from 'react'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

const TextEditor = ({ onChange, clearEditor }) => {
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current = new Quill(quillRef.current, {
        theme: `snow`,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [`bold`, `italic`, `underline`, `strike`],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ list: `ordered` }, { list: `bullet` }],
            [`image`, `code-block`],
            [`clean`],
            [`undo`, `redo`],
          ],
        },
      })

      // Add undo/redo functionality
      const toolbar = quillRef.current.getModule(`toolbar`)
      toolbar.addHandler(`undo`, () => {
        quillRef.current.history.undo()
      })
      toolbar.addHandler(`redo`, () => {
        quillRef.current.history.redo()
      })

      // Handle text changes and notify parent
      quillRef.current.on(`text-change`, () => {
        const content = quillRef.current.root.innerHTML
        onChange(content) // Notify parent with the updated content
      })

      //explicitly removed formula option
      const formulaButton = document.querySelector(`.ql-clean`)
      if (formulaButton) formulaButton.remove()
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off(`text-change`)
        quillRef.current = null
      }

      // Remove toolbar and other Quill-related DOM elements
      const toolbar = document.querySelector(`.ql-toolbar`)
      if (toolbar) toolbar.remove()

      const editor = document.querySelector(`.ql-container`)
      if (editor) editor.remove()
    }
  }, [])

  // Clear the editor's content
  useEffect(() => {
    if (clearEditor && quillRef.current) {
      quillRef.current.root.innerHTML = ``
    }
  }, [clearEditor])

  return <div ref={quillRef} style={{ height: `200px` }} />
}

export default TextEditor
